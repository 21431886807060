import React, { useEffect, useState } from 'react'
import './Blog.css'
import { useLocation } from 'react-router-dom'
import articleIcon from '../img/Article_icon.png'
import articleIconPDF from '../img/Article_icon_pdf.png'
import { Link } from 'react-router-dom'

import db from '../../firebase'
import {    
    collection,
    query,
    where,
    getDocs
} from 'firebase/firestore'
import { storage } from '../../firebase'
import { ref, getDownloadURL } from 'firebase/storage'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';

const PostPage = () => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

    const location = useLocation();
    const postID = location.pathname.split('/');
    const cutID = postID[postID.length-1];

    const blogRef = collection(db, 'blog');
    const q = query(blogRef, where('__name__', '==', cutID));
    const [post, setPost] = useState({ 
        author: '', 
        date: '', 
        hasDoc: '',
        id: '',
        img: '', 
        summary: '',
        url: ''
    });
    const [downloadLink, setDownloadLink] = useState(null);
    const [visitLink, setVisitLink] = useState(null);
    useEffect(() => {
        const getPost = async () => {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setPost({ ...doc.data(), id: doc.id });

                // download file if the post has one
                if (doc.data().hasDoc) {
                    const storageRef = ref(storage, 'blog_docs/' + doc.data().title.replaceAll(' ', '_') + '-_-' + doc.id);
                    getDownloadURL(storageRef).then((url) => {
                        // route request through CORS proxy
                        setVisitLink(url);
                        setDownloadLink('https://polar-caverns-58776.herokuapp.com/' + url);
                    })
                    .catch((error) => {
                        console.log('error: ' + error);
                    })
                }
            })
        }

        getPost();
    }, [])

    const [numPages, setNumPages] = useState(null);
    //const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    // zoom levels
        // in 100, 110, 125, 150, 175, 200, 300, 400, 500
        // out 100, 90, 80, 75, 67, 50, 33, 25
    const zoomPercentage = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 300, 400, 500];
    const [zoom, setZoom] = useState(zoomPercentage.indexOf(100));
    const zoomIn = () => {
        if (zoomPercentage[zoom] < 500) setZoom(zoom + 1);
    }

    const zoomOut = () => {
        if (zoomPercentage[zoom] > 25) setZoom(zoom - 1);
    }

    const renderLink = () => {
        return (
            <div style={{ width:'100%', wordWrap:"break-word", paddingBottom:"50px" }}>
                <a  href={ visitLink } target="_blank" rel="noopener noreferrer">{ visitLink }</a>
            </div>
        )
    }

    return (
        <div className='blog-container-main'>
            <div className='blog-shifting-container'>
                <div className='post-container'>
                    <div className='post-header'>
                        <img className='post-img' src={ post.img ? post.img : post.hasDoc ? articleIconPDF : articleIcon } alt="article icon"/>
                        <div className='post-heading'>
                            <h2>{ post.title }</h2>
                            <h3>{ post.author }</h3>
                            <p className='post-date'>Date Posted: { post.date }</p>
                        </div>
                    </div>
                    { post.summary ? 
                        <div className='post-summary'>
                            <h3>Post Summary: </h3>
                            <p>{ post.summary }</p>
                        </div>
                        :
                        <div />
                    }
                    { post.url ? 
                        <div className='post-link'>
                            <h3>Link to Article:</h3>
                            <a href={ post.url } target="_blank" rel="noopener noreferrer">{ post.url }</a>
                        </div>
                        :
                        <div />
                    }
                    { post.hasDoc && 
                        <div className='post-pdf'>
                            <h3 style={{ paddingBottom:'20px' }}>PDF View</h3>

                            { renderLink() }

                            {/* <div className='post-pdf-header'>
                                    <p>{ numPages } { numPages === 1 ? 'page' : 'pages' }</p>
                                    <div className='post-pdf-zoom'>
                                        <button onClick={ zoomOut }>-</button>
                                        <span>{ zoomPercentage[zoom] }%</span>
                                        <button onClick={ zoomIn }>+</button>
                                    </div>

                                    <a className='post-pdf-download' href={ visitLink } target="_blank" rel="noopener noreferrer">
                                        Download/View
                                    </a>
                            </div>
                            <Document 
                                className='post-pdf-doc'
                                file={ downloadLink } 
                                onLoadSuccess={ onDocumentLoadSuccess }
                                style={{ marginTop: 100 }}
                            >
                                { Array.from(new Array(numPages), (el, index) => (
                                    <Page 
                                        className='post-pdf-page' 
                                        key={ `page_${index + 1}` } 
                                        pageNumber={index + 1}
                                        canvasBackground='#fff'
                                        height={ 1000 * (zoomPercentage[zoom]/100) }
                                        renderAnnotationLayer={ false }
                                    />
                                )) }
                            </Document> */}
                        </div>

                    }
                </div>
                
            </div>
        </div>
    )
}

export default PostPage