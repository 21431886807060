import React, { useState, useEffect } from 'react'
import '../pages/Blog-pages/Blog.css'
import { useLocation, Link } from 'react-router-dom'

import articleIcon from '../pages/img/Article_icon.png'
import articleIconPDF from '../pages/img/Article_icon_pdf.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import db from '../firebase'
import {    
    doc,
    updateDoc,
    deleteDoc
} from 'firebase/firestore'

import { storage } from '../firebase'
import { ref, deleteObject, uploadBytesResumable } from 'firebase/storage'

const BlogList = ({
    author,
    date,
    hasDoc,
    id,
    img, 
    summary,
    title,
    url
}) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const location = useLocation();
    const blogLink = '/post/' + title.replaceAll(' ', '_') + '/' + id;

    const [entry, setEntry] = useState(null);
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
        setEntry({ 
            'author': author,
            'date': date,
            'hasDoc': hasDoc,
            'img': img,
            'summary': summary,
            'title': title,
            'url': url
        });
    }, [author, date, hasDoc, img, summary, title, url, clicked])

    const handleChange = (e) => {
        e.preventDefault();
        setEntry((fields) => ({ ...fields, [e.target.id]: e.target.value}));
    }

    const docRef = doc(db, 'blog', id);

    const handleClick = (e) => {
        e.preventDefault();
        setClicked(!clicked);
    }
    
    const toastId = React.useRef(null);
    const sendEdit = async (e) => {
		e.preventDefault();

        await updateDoc(docRef, {
            author: entry.author,
            date: entry.date,
            hasDoc: entry.hasDoc,
            img: entry.img,
            summary: entry.summary,
            title: entry.title,
            url: entry.url
        });


        if (selectedFile) {
            const storageRef = ref(storage, 'blog_docs/' + entry.title.replaceAll(' ', '_') + '-_-' + id);
            if (hasDoc) {
                // delete existing document if it exists
                deleteObject(storageRef).then(() => {
                    console.log('deleted');
                }).catch((error) => {
                    console.log('Delete error: ' + error);
                })
            }

            // upload new document
            toastId.current = toast.loading('Document is being uploaded');
            const uploadTask = uploadBytesResumable(storageRef, selectedFile);
            uploadTask.on('state_changed',
                (snapshot) => {
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    toast.update(toastId.current, { render: 'Upload success', type: 'success', isLoading: false });
                    window.location.reload();
                }
            );
        } else {
            window.location.reload();
        }

	}

    const handleDelete = async (e) => {
        e.preventDefault();
        // Delete database document
        await deleteDoc(docRef);

        if (hasDoc) storageDelete();
        else window.location.reload();
    }

    const storageDelete = () => {
        // Delete file storage
        const storageRef = ref(storage, 'blog_docs/' + title.replaceAll(' ', '_') + '-_-' + id);
        deleteObject(storageRef).then(() => {
            console.log('file delete success');
            window.location.reload();
        }).catch((error) => {
            console.log('error: ' + error);
        })
    }

    const [selectedFile, setSelectedFile] = useState(null);
	const fileChange = (e) => {
		if (!e.target.files.length) {
			setSelectedFile(null);
			return;
		}
		setSelectedFile(e.target.files[0]);
        entry.hasDoc = true;
	}

    return (
        <React.Fragment>
            { clicked? 
                <div className='bloglist-container'>
                    <div className='bloglist-menu-background' />
                    <div className='bloglist-edit-menu'>
                        <div className='bloglist-top-row'>
                            <FontAwesomeIcon 
                                icon={ faXmark } 
                                onClick={ handleClick }
                                className='bloglist-x-symbol'
                            />
                            <p>Edit Post</p>
                        </div>
                        <form className='bloglist-edit-form'>
                            <span>Title</span>
                            <input
                                onChange={ handleChange }
                                value={ entry.title }
                                id='title'
                                type='text'
                            />
                            <span>Author</span>
                            <input
                                onChange={ handleChange }
                                value={ entry.author }
                                id='author'
                                type='text'
                            />
                            <span>Date</span>
                            <input
                                onChange={ handleChange }
                                value={ entry.date }
                                id='date'
                                type='text'
                            />
                            <span>Image</span>
                            <input
                                onChange={ handleChange }
                                value={ entry.img }
                                id='img'
                                type='text'
                            />
                            <span>Website Link</span>
                            <input
                                onChange={ handleChange }
                                value={ entry.url }
                                id='url'
                                type='text'
                            />
                            <span>Change PDF file</span>
								<input 
									type='file' 
									name='file' 
									onChange={ fileChange } 
							/>
                            <span>Summary</span>
                            <textarea
                                onChange={ handleChange }
                                value={ entry.summary }
                                id='summary'
                                type='text'
                                rows='5'
                            />
                        </form>
                        <div className='bloglist-buttons'>
                            <button onClick={ sendEdit }>Submit Changes</button>
                            <button 
                                onClick={ handleDelete }
                                style={{ background: '#ed5e68' }}
                            >
                                Delete entry
                            </button>
                        </div>
                    </div>
                </div>
                :
                <div key='bloglist-menu' />
            }
            <Link to={ blogLink } onClick={ scrollToTop } style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="bloglist-row">
                    <div className='bloglist-left'>
                        <div className='bloglist-icon-container'>
                            <img className="bloglist-icon" src={ img ? img : hasDoc ? articleIconPDF : articleIcon } alt="article icon"/>
                        </div>
                        <div className='bloglist-text'>
                            <p>{ title }</p>
                            <span>{ date }</span>
                        </div>
                    </div>
                    { location.pathname === '/admin' ? 
                        <button onClick={ handleClick } className='bloglist-edit'>Edit</button>
                    :
                        <div />
                    }
                </div>
            </Link>
            <ToastContainer 
					hideProgressBar={ true }
					limit={ 1 }
					transition={ Slide }
					autoClose={ 3000 }
					position={ toast.POSITION.TOP_CENTER }
            />

        </React.Fragment>
    )
}

export default BlogList;